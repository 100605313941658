// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-chinois-js": () => import("./../../../src/pages/chinois.js" /* webpackChunkName: "component---src-pages-chinois-js" */),
  "component---src-pages-coreen-js": () => import("./../../../src/pages/coreen.js" /* webpackChunkName: "component---src-pages-coreen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thai-js": () => import("./../../../src/pages/thai.js" /* webpackChunkName: "component---src-pages-thai-js" */)
}

